<template>
  <div id="loginPage">
    <div class="header">
      <img src="static/img/logo.svg" alt="" />
    </div>
    <div class="login-box">
      <h3 class="title">{{title}}</h3>
      <div class="login-flexBox">
        <div class="loginPic">
          <img src="static/img/loginPic.png" alt="" />
        </div>
        <el-form
          :model="dataForm" :rules="rules"
          @keyup.enter.native="submit()" status-icon
          ref="loginForm" label-position="right" label-width="100px"
          class="loginForm"
        >
          <p class="lead">账号密码登录</p>
          <el-form-item prop="username" class="inputBox" label="用户名">
            <el-input id="username"
              type="text"
              v-model="dataForm.username"
              auto-complete="off"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password" class="inputBox" label="密码">
            <el-input id="password"
              type="password"
              v-model="dataForm.password"
              auto-complete="off"
            ></el-input>
          </el-form-item>
          <el-form-item prop="captcha" class="inputBox" label="验证码">
            <el-row>
              <el-col :span="12">
                <el-input id="captcha"
                  type="text"
                  v-model="dataForm.captcha"
                  auto-complete="off"
                ></el-input>
              </el-col>
              <el-col :span="12">
                <div class="captchaBox" @click="changeCaptcha">
                  <img :src="captchaUrl" alt="点击刷新" />
                </div>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label-width="0px" class="submitBtn">
            <el-button
              type="primary"
              @click="submit()"
              :loading="logining"
            >
              立即登录
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="footer">
      <p>Copyright &copy; 2020 - 2022 <a href="http://www.fzmts.com" target="_blank" rel="noopener">FZMTS Co.</a></p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import _ from 'lodash'
import api from '@/lib/api'

export default {
  name: 'Home',
  data () {
    return {
      timestamp: Date.now(),
      title: process.env.VUE_APP_TITLE,
      dataForm: {
        username: '',
        password: '',
        captcha: ''
      },
      rules: {
        username: [
          { required: true, message: "请输入您的账号", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入您的密码", trigger: "blur" },
        ],
        captcha: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
      },
      logining: false
    }
  },
  mounted () {
    this.userInfo().then(() => {
      this.$router.push('home')
    })
  },
  computed: {
    captchaUrl() {
      return api.getUrl('./captcha') + `?_t=${this.timestamp}`
    }
  },
  methods: {
    ...mapActions('system', ['login', 'userInfo']),
    changeCaptcha () {
      this.timestamp = Date.now()
      document.querySelector('#captcha').focus()
    },
    submit () {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.logining = true
          let form = _.cloneDeep(this.dataForm)
          this.login(form).then(() => {
            // 登录成功
            this.logining = false
            this.$router.push('home')
          }).catch((e) => {
            this.logining = false
            this.timestamp = Date.now()
            this.$alert(e.message, '登录', { type: 'error' })
          })
        } else{
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  #loginPage {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('~@/../public/static/img/bkg.png') no-repeat scroll center center;
    background-size: cover;
    user-select: none;
    color: #fff;
    min-width: 1024px;
    min-height: 640px;

    .header {
      position: absolute;
      top: 30px;
      left: 30px;
      z-index: 5;
      height: 90px;
      box-sizing: border-box;

      > img {
        display: block;
        height: 100%;
        width: auto;
      }
    }

    .footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      padding: 1em 0;
      z-index: 5;
      display: none;

      > p {
        margin: 0;
        font-size: 12px;
        text-align: center;
        color: #999;
      }

      a, a:active, a:visited {
        color: #999;
        text-decoration: none;
      }
    }

    .login-box {
      position: relative;
      background-color: rgba(255, 255, 255, .4);
      border-radius: 30px;
      border: 1px solid rgba(255, 255, 255, .4);
      padding: 30px 0;
      min-width: 1000px;
      z-index: 8;
    }

    .login-flexBox {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 50px;

      .loginPic {
        width: 580px;
        height: 465px;

        > img {
          width: 100%;
          height: auto;
        }
      }

      .loginForm {
        width: 300px;
        margin-right: 70px;
        margin-top: 30px;

        .submitBtn {
          text-align: center;
          margin-top: 30px;

          /deep/ .el-button {
            width: 180px;
            height: 40px;
            border-radius: 20px;
            line-height: 1.5em;
            background-color: rgba(67, 113, 244, 1);
            border: 1px solid rgba(67, 113, 244, 1);
            color: #fff;
            font-size: 16px;
            padding: 6px;
          }
        }

        p.lead {
          font-size: 24px;
          text-align: center;
          line-height: 1.5em;
          font-weight: bolder;
          margin: 30px 0;
          color: rgba(44, 55, 162, 1);
        }

        .captchaBox {
          margin-left: -8px;
          padding-top: 5px;
          cursor: pointer;
          img {
            width: 100%;
          }
        }

        .inputBox {
          border-radius: 5px;
          background-color: rgba(238, 250, 255, .7);
          width: 100%;
          height: 44px;
          margin-bottom: 20px;
          /deep/ label {
            color: rgba(44, 55, 162, 1);
            font-weight: normal;
            font-size: 16px;
            line-height: 44px;
            &::before {
              display: none;
            }

            &::after {
              content: "：";
            }
          }

          /deep/ .el-input input {
            background: transparent;
            border: none;
            font-size: 16px;
            line-height: 44px;
            height: 44px;
            padding-left: 0;
            color: rgba(44, 55, 162, 1);
          }
        }
      }
    }

    .login-page {
      -webkit-border-radius: 5px;
      border-radius: 5px;
      width: 350px;
      padding: 35px 35px 15px;
      border: 1px solid #eaeaea;
      box-shadow: 0 0 25px #cac6c6;
    }

    h3.title {
      text-align: center;
      font-size: 40px;
      font-weight: bolder;
      color: rgba(109, 107, 110, 1);
      letter-spacing: .18em;
      z-index: 8;
    }
  }
</style>